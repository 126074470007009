/** @jsxImportSource @emotion/react */
import React from 'react';
import { Col, Row } from 'antd';
import { css } from '@emotion/react';

import { colors, fontSizes } from 'src/constants/variables';
import { ItemElementPriceCell, itemElementPriceCellStyle } from './ItemElementPriceCell';
import { AdditionalItem } from '../../../../../helpers/types';
import { AdditionalHeaderColumn } from './AdditionalHeaderColumn';

type Props = {
    item: AdditionalItem;
    index: number;
};

export const AdditionalItemElement: React.FC<Props> = ({ item, index }) => (
    <Row css={styles.wrapper}>
        <Col span={1} css={styles.indexColumn}>
            {index + 1}
        </Col>

        <Col span={17}>
            <AdditionalHeaderColumn item={item} />
        </Col>

        <Col span={2}>
            <div css={itemElementPriceCellStyle.section}>{item.amountOfWearers}</div>
        </Col>

        <Col span={2}>
            <ItemElementPriceCell price={item.basePrice} />
        </Col>

        <Col span={2}>
            <ItemElementPriceCell last bold price={item.pricePerWearer} />
        </Col>
    </Row>
);

const styles = {
    wrapper: css`
        border-bottom: 1px solid ${colors.backgroundDark}40;

        &:last-of-type {
            border-bottom: none;
        }
    `,
    indexColumn: css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 6px;
        background-color: ${colors.primary};
        font-size: ${fontSizes.mSmall};
        font-family: 'Gebauer-Regular', sans-serif;
        color: ${colors.fontHeader};
    `,
};
