import FileSaver from 'file-saver';
import { Font, pdf } from '@react-pdf/renderer';
import {
    getIntroPages,
    offerPdfLastPages, splitIntroText,
} from 'src/modules/offerPdf/templates/offerPdfTemplate';
import GebauerRegular from 'src/style/fonts/Gebauer-Regular.otf';
import GebauerMedium from 'src/style/fonts/Gebauer-Medium.otf';
import PDFMerger from 'pdf-merger-js/browser';
import { secondPagePDF } from '../../../assets/pdf/secondPage';
import { termsAndConditionsPDF } from '../../../assets/pdf/termsAndConditions';
import { VariantItem } from '../../../helpers/types';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

Font.register({ family: 'GebauerRegular', fonts: [{ src: GebauerRegular }] });
Font.register({ family: 'GebauerMedium', fonts: [{ src: GebauerMedium }] });
Font.register({ family: 'GebauerBold', fonts: [{ src: GebauerMedium }] });

type offerForPdf = {
    items: VariantItem[];
    basics: OfferBasics | undefined;
    details: OfferDetails | undefined;
    displayCategory: boolean;
} | null;

const checkItemCategory = function (items: VariantItem[] | undefined, category: string): boolean {
    return items?.filter((item) => item.category === category).length !== 0;
};

const fetchFileAsBlob = async function (file: any): Promise<Blob> {
    const response = await fetch(file);
    return await response.blob();
};

async function mergePdf(merger: PDFMerger, pdfElement: EmotionJSX.Element) {
    const newPdf = pdf();
    newPdf.updateContainer(pdfElement);

    const newPdfBlob = await newPdf.toBlob();

    await merger.add(newPdfBlob);
}

export async function createOfferPdf(offerOverview: offerForPdf) {
    const mbsItems = checkItemCategory(offerOverview?.items, 'MBS');
    const mbkItems = checkItemCategory(offerOverview?.items, 'MBK');
    offerOverview!.displayCategory =
        mbsItems && mbkItems && offerOverview!.details!.includeTermsAndConditions;

    const merger = new PDFMerger();

    await mergePdf(merger, getIntroPages(offerOverview));

    const longIntro = splitIntroText(offerOverview)[1] !== '';
    const secondPagePdf = longIntro ? await fetchFileAsBlob(secondPagePDF.page3) : await fetchFileAsBlob(secondPagePDF.page2);
    await merger.add(secondPagePdf);

    await mergePdf(merger, offerPdfLastPages(offerOverview));

    if (offerOverview!.details!.includeTermsAndConditions) {
        if (mbsItems) {
            const mbsBlob = await fetchFileAsBlob(termsAndConditionsPDF.MBS);
            await merger.add(mbsBlob);
        }
        if (mbkItems) {
            const mbkBlob = await fetchFileAsBlob(termsAndConditionsPDF.MBK);
            await merger.add(mbkBlob);
        }
    }

    const mergedPdf = await merger.saveAsBlob();
    return FileSaver.saveAs(mergedPdf, `${offerOverview?.details?.name}.pdf`);
}
